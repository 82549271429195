body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root {
  --primary: #000000;

  /*Button colors*/
  --button-primary: #130f26;

  /*Text colors*/
  --text-primary: #130f26;

  /*Font family*/
  --font-family: "Montserrat";
}
@media screen and (max-width: 768px) {
  :root {
    /*Button colors*/
    --button-primary: #000000;

    /*Text colors*/
    --text-primary: #000000;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.2px;
}

@media screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

.custom-popover
  > .ant-popover-content
  > .ant-popover-inner
  > .ant-popover-inner-content {
  padding: 0px;
}
.custom-popover > .ant-popover-content > .ant-popover-inner {
  border-radius: 10px;
}

.custom-popover > .ant-popover-content > .ant-popover-arrow {
  display: none !important;
}
.new-asset-ion-content {
  --ion-background-color: #f5f5f5;
}

.chat-popover {
  width: 100% !important;
}
.chat-popover > .ant-popover-content > .ant-popover-arrow {
  display: none !important;
}
.chat-popover > .ant-popover-content > .ant-popover-inner {
  border-radius: 30px 30px 0 0;
  box-shadow: 0 -9px 8px -5px #bdb9b973;
  border-bottom: 1px solid #ccc;
}
.chat-popover
  > .ant-popover-content
  > .ant-popover-inner
  > .ant-popover-inner-content {
  padding: 1rem 5%;
}
